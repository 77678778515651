<template lang="html">
  <v-row no-gutters align="stretch" :class="{'mb-1' : $isUndefined(date) || $isEmpty(date), 'mb-3': !$isUndefined(date) || !$isEmpty(date)}">

    <v-col cols="auto" class="mr-2 d-flex flex-column justify-end align-items-end" @click="gotoUser">
      <v-avatar size="30" :class="{'hidden' : $isUndefined(date) || $isEmpty(date)}"><v-img :src="photoURL_thumb"></v-img></v-avatar>
    </v-col>

    <v-col cols="" class="message">
      <div class="pa-2 rounded grey lighten-1 white--text d-flex align-start flex-column justify-start">
        <template v-if="!$isUndefined(date) || !$isEmpty(date)">
          <template v-if="date.date == 'sendASAP'">
            <div class="subtitle-1">
              <strong>I'd like to meet as soon as possible, or when convenient.</strong>
            </div>
          </template>
          <template v-else>
            <div class="subtitle-1">
              <div class="mb-1">Would this time work?</div>
              <strong>{{$date.utc(date.date).local().format("MMM D")}}</strong>
            </div>
            <span>{{$date.utc(date.date).local().format("h:mm a")}} - {{$date.utc(date.date).add(30, 'minute').local().format("h:mm a")}}</span>
          </template>
        </template>

        <template v-else>
          <span>Hey, I'm {{proposal.client.firstName}},</span>
          <div class="mb-0">
            {{proposal.description}}
          </div>
          <div class="d-flex">
            <span class="pr-1">Topics:</span>
            <span class="font-weight-bold" v-for="(topic, ind) in proposal.topics " :key="`propTopic-${ind}`">{{topic.name}}<span v-if="ind != proposal.topics.length - 1" class="pr-1">, </span> </span>
          </div>
        </template>
      </div>
    </v-col>
    <!-- show accept/reject button -->
    <!-- <template v-if="!$isUndefined(date) || !$isEmpty(date)">
      <v-col v-if="!date.approved && (proposal.turn !== date.who )" cols="auto" class=" d-flex flex-column justify-center align-items-end" @click="acceptMeeting">
        <v-btn color="primary" class="ml-2" small :disabled="timeEdited" @click="acceptMeeting"> accept</v-btn>
      </v-col>
    </template> -->

  </v-row>
</template>

<script>
export default {
  name:'leftBubble',
  props: ['index', 'date', 'photoURL_thumb', 'proposal', 'whoIsUser', 'timeEdited', 'userName'],
  methods:{
    acceptMeeting(){
      if(this.timeEdited === false){
        this.$emit('accept-meeting', this.index)
      }
    },
    gotoUser(){
      this.$router.push({ name: 'User', params: { userName: this.userName} }).catch(()=>{});
    }
  }
}
</script>

<style lang="css" scoped>
.hidden{
  visibility: hidden;
}
.message{
  max-width: 70%;
}
</style>
